<template>
    <div>
        <Header>
            <template v-slot:left></template>
            <template v-slot:mid>
                itrax服务
            </template>
        </Header>
        <div class="col-wrap ">
            <img src="@/assets/mobile/customer/banner-bg.jpg" />
            <div class="mask banner">
                <h3>行业趋势分析、数据分析、</h3>
                <h1>精准策略创意、专业服务执行</h1>

                <p>
                    Industry trend analysis, data analysis, precise and creative strategy and professional service performance
                </p>
            </div>
        </div>
        <div class="col-wrap customer">
            <img class="customer-tit" src="@/assets/mobile/customer/customer-tit.png" />
            <p style="font-size:12px;color:#666;margin-bottom:20px;">让营销与传播更富实效，不断实现品牌与市场的同步发展</p>
            <ul>
                <li :class="tabIdx == 0 ? 'active' : ''" @click="tabHandle(0)">汽车类</li>
                <li :class="tabIdx == 1 ? 'active' : ''" @click="tabHandle(1)">快消/金融/家具</li>
                <li :class="tabIdx == 2 ? 'active' : ''" @click="tabHandle(2)">3C/IT</li>
            </ul>
            <div class="list" v-show="tabIdx == 0">
                <img v-if="customer" :src="resourceBaseUrl + customer.mobileAuto + '?x-oss-process=image/resize,w_800'" />
            </div>
            <div class="list" v-show="tabIdx == 1"><img v-if="customer" :src="resourceBaseUrl + customer.mobileFmcg + '?x-oss-process=image/resize,w_800'" /></div>
            <div class="list" v-show="tabIdx == 2"><img v-if="customer" :src="resourceBaseUrl + customer.mobileIt + '?x-oss-process=image/resize,w_800'" /></div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "../../components/mobile/header.vue";
import Footer from "../../components/mobile/footer.vue";
import settings from "@/settings";
export default {
    name: "mobile-customer",
    components: { Header, Footer },
    data() {
        return {
            tabIdx: 0,
            resourceBaseUrl: settings.resourceBaseUrl,
            customer: null,
        };
    },
    mounted() {
        this.loadCustomer();
    },
    methods: {
        tabHandle(e) {
            this.tabIdx = e;
        },
        loadCustomer() {
            this.$http.get("/api/customer/config").then((res) => {
                this.customer = res.data;
            });
        },
    },
};
</script>
<style scoped>
@import url("../../../common/css/mobile.css");

.list {
    margin: 20px 15px 40px 15px;
}
.customer ul {
    margin: 0 15px;
    display: flex;
    border-bottom: 1px #adadad solid;
}
.customer ul li {
    flex: 1;
    padding: 15px 0;
    font-size: 15px;
    color: #666666;
}
.customer ul .active {
    color: #0048b1;
    border-bottom: 3px #0048b1 solid;
}

.customer-tit {
    width: 70%;
    margin: 20px 0 0 0;
}
.banner {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.banner h1 {
    font-size: 20px;
    margin: 20px 0;
}
.banner h3 {
    font-size: 15px;
}
.banner p {
    font-size: 12px;
    margin: 0 15px;
}
</style>
